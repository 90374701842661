.alertContent {
  background-color: #121212;
}
@media (max-width: 960px) {
  .alertContent {
    padding: 0px;
  }
  .alertMessage {
    width: 75%;
  }
}

.closeIcon {
  color: #fff;
  font-size: 20;
}

.messageIcon {
  opacity: 0.9;
  margin-right: 8px;
  font-size: 20;
}

.messageContainer {
  display: flex;
  align-items: center;
}

.message {
  color: #ffffff;
}

.success {
  background-color: #22bb34;
}

.error {
  background-color: #f44336;
}

.warning {
  background-color: #ffcc80;
}

.info {
  background-color: #29b6f6;
}
