.customTooltip {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tooltipLabel {
  font-weight: bold;
  margin-bottom: 5px;
}

.tooltipValue {
  color: #888;
}
